.hero-section {
    display: flex;
    position: sticky;
    width: 100%;
    overflow: hidden;
    min-height: 72vh;
}

/* this targets the first div of hero-section,
   which contains the main hero text and button.
   positioning it absolutely in the center of the section ensures
   it remains in the center regardless of screen size.
*/

.hero-section > div:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /*
       translates the element by 50% of its own width and height - this ensures the element is perfectly centered.
       reference: https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/translate
    */
    text-align: center;
    color: white;
    z-index: 2;
    text-wrap: nowrap;
    padding-bottom: 80px;
}

.hero-section h1 {
    font-size: 3.75rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    line-height: 1.33;
}

.h1-ranille {
    font-family: 'Ranille', sans-serif;
    line-height: 1;
    font-style: normal;
    text-transform: lowercase;
}

.hero-section p {
    font-size: 1.5rem;
    margin: 0.5rem 0;
}

.hero-section a {
    font-size: 1rem;
    line-height: 1.19rem;
    font-weight: 700;
    padding: 0.69rem 0.63rem 0.63rem;
    margin-top: 2rem;
    background-color: #fff;
    color: #00359a;
    border: 1px solid #00359a;
    border-radius: 0.63rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    transition: background-color 0.3s, color 0.3s;
}

.hero-section a:hover {
    background-color: #00359a;
    color: #fff;
}

/* responsive image */
.hero-section picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.hero-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


