/* main header */
#main-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 0.63rem;
    padding-bottom: 0.63rem;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.2);
}

/* logo */
#main-header h1 {
    padding-left: 20px;
    padding-right: 20px;
    /* margin-top: 10px; */
    margin-bottom: 10px;
}

#main-header h1 img {
    width: clamp(195px, 20vw, 195px); /* this is to avoid using the media query for the image */
    height: 39.75px;
}

/* search and profile buttons */
#main-header > section {
    display: flex;
    gap: 0.3rem;
}

#main-header button {
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    color: #00359a;
}

/* button hover effects */
#main-header button svg {
    fill: #00359a;
}

#main-header button:hover {
    background-color: #00359a;
    border-color: #00359a;
}

#main-header button:hover svg {
    fill: white;
}
