
/* hide the hamburger button and menu on larger screens */
#main-header .hamburger-button,
#menu-mobile {
    display: none;
    color: #00359a;
}

#main-header button[aria-label="Search"]:hover {
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    background-color: #00359a;
    border-color: #00359a;
    color: white;
}

/* hide the menu toggle checkbox */
.menu-toggle-checkbox {
    display: none;
}


@media (max-width: 1200px) {
    /* switch #main-header to grid layout */
    #main-header {
        padding-top: 10px;
        padding-bottom: 0;
        display: grid;
        grid-template-columns: 1fr minmax(0, auto) minmax(0, auto);
        gap: 0;
    }

    /* adjust header title spacing */
    #main-header h1 {
        margin-top: 0;
        margin-bottom: -5px;
        margin-left: 0;
        padding-left: 20px;
        padding-right: 0;
        padding-bottom: 0;
    }

    /* hide the desktop navigation on mobile */
    #main-header #desktop-nav {
        display: none;
    }

    /* show hamburger and search buttons on smaller screens */
    #main-header .hamburger-button,
    #main-header button[aria-label="Search"] {
        display: flex;
        justify-content: center;
        align-items: center;
        /* 40px */
        width: 39px;
        height: 39px; /* 40px */
        border: 0.063rem solid #ccc; /* 1px */
        border-radius: 50%;
        background-color: transparent;
        transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    /* adjust the position of the section for buttons */
    #main-header > section {
        align-items: center;
        /* margin-right: 20px; */ /* 20px */
        padding-right: 20px;
        padding-bottom: 9px;
        gap: 5px;
    }

    /* hide the login button on smaller screens */
    #main-header button[aria-label="Login"] {
        display: none;
    }

    .main-menu {
        padding-top: 2rem; /* 20px */
        padding-left: 1.5rem; /* 20px */
        padding-right: 1.5rem; /* 20px */
    }

    /* show the mobile menu when the checkbox is checked */
    #menu-mobile {
        display: flex;
        position: absolute;
        top: 5.7rem;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #00359a;
        color: white;
        z-index: 1000;
        justify-content: space-between;
        flex-direction: column;
        text-transform: uppercase;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;
    }

    .menu-toggle-checkbox:checked + #menu-mobile {
        opacity: 1;
        pointer-events: auto;
    }

    /* styling for anonymous user section in mobile menu */
    #menu-mobile .anonymous-user {
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 0.625rem; /* 10px */
        padding-bottom: 1.5rem; /* 20px */
        border-bottom: 0.063rem solid; /* 1px */
        margin-bottom: 0.3125rem; /* 5px */
    }

    .anonymous-user a {
        text-decoration: none;
        color: white;
        border: 1px solid;
        width: 50%;
        text-align: center;
        border-radius: 20px;
        padding: 1.4vh;
        position: relative;
        font-size: 1.9vh;
        line-height: 1.6rem;
    }

    /* position svg icons within the anonymous user section */
    .anonymous-user svg {
        position: absolute;
        right: 0.625rem; /* 10px */
        top: 50%;
        transform: translateY(-50%);
    }

    /* mobile navigation links styling */
    #menu-mobile .mobile-nav {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        list-style: none;
        padding-left: 0;
    }

    /* individual mobile nav link styles */
    .mobile-nav li {
        border-bottom: 0.063rem solid #ddd; /* 1px */
        font-size: 0.675rem; /* 14px */
        /* line-height: 1.5rem; */ /* 16px */
        font-weight: 700;
        color: inherit;
        padding-bottom: 0.625rem; /* 10px */
    }

    .mobile-nav li a {
        font-size: 1.9vh !important; /* 14px */
        line-height: 1.5rem !important; /* 16px */
        font-weight: 700;
        color: white;
        text-decoration: none;
        padding: 0.62rem 0; /* 10px */
        transition: color 0.3s ease-in-out;
    }

    .mobile-nav li:first-of-type {
        padding-top: 0.62rem;
    }

    .mobile-nav li:nth-child(4) a {
        font-weight: 400;
    }

    .mobile-nav li:last-of-type {
        border-bottom: 0px;
    }

    /* hover effect for mobile nav links */
    .mobile-nav li a:hover {
        color: #28c83c;
        transition: color 0.3s ease-in-out;
    }

    /* styling for mobile bottom info section */
    #menu-mobile .mobile-bottom {
        margin-bottom: 7rem;
        text-transform: none;
        /* margin-top: 2rem; */
        padding-left: 1.25rem; /* 20px */
        padding-right: 1.25rem; /* 20px */
    }

    /* heading and paragraph styles in mobile bottom section */
    .mobile-bottom h2 {
        font-size: 1.5rem; /* 16px */
        /* line-height: 1.1875rem; */ /* 19px */
        font-weight: 700;
        margin-bottom: 0.5rem; /* 5px */
    }

    .mobile-bottom p {
        font-size: 12px;
        margin: 0;
        line-height: 1.2;
    }

    .mobile-bottom p:last-of-type {
        font-style: italic;
    }

    .mobile-bottom a {
        text-decoration: none;
        color: inherit;
    }

    /*  x icon when the checkbox is checked and hide the list (hamburger) icon */
    #menu-toggle:checked + #menu-mobile ~ section label #x-icon {
        display: block;
        fill: white;
        opacity: 1;
    }

    #menu-toggle:checked ~ section label.hamburger-button {
        background-color: #00359a;
        color: white;
    }

    #menu-toggle:checked + #menu-mobile ~ section label #list-icon {
        display: none;
        opacity: 0;
    }

    /* hide X (in initial state) */
    #x-icon {
        display: none;
        opacity: 0;
    }

    .hero-section {
        min-height: 50vh;
        /* the min-height for mobile views */
    }

    .hero-section h1 {
        font-size: 4.5vh;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        /* line-height: 36px; */
        margin-bottom: 1.5rem;
    }

    .hero-section p {
        font-size: 2vh;
        line-height: 1.2;
        margin-bottom: 1.25rem;
    }

    .hero-section a {
        font-size: 16px;
        line-height: 19px;
        font-weight: 700;
        padding: 11px 10px 10px;
        margin-top: 0;
        background-color: #fff;
        color: #00359a;
        border: 1px solid #00359a;
        border-radius: 10px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        gap: 2rem;
        transition: background-color 0.3s, color 0.3s;
    }

    /* CTA'S */


/* hides dropdown menu by default */
.mobile-dropdown-menu {
    overflow: hidden;
    max-height: 0;
    padding-left: 0;
    list-style: none;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: 1;
}

/* styling for the dropdown toggle button */
.dropdown-toggle {
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    display: inline-flex;
    background: transparent;
    border: none;
    cursor: pointer;
    margin-left: 0.5rem;
    align-items: center;
}

/* align the button inline with the menu item */
.dropdown-toggle svg {
    transition: all 0.3s ease;
    margin-bottom: 0.625rem; /* 10px */
    position: absolute;
    right: 1.625rem; /* 26px */
    vertical-align: middle;
    fill: white !important;
}

/* hidden checkbox to toggle the dropdown menu */
.dropdown-checkbox {
    display: none;
    height: 0;
    transition: height .35s ease;
}

/* shows dropdown when checkbox is checked */
.dropdown-checkbox:checked ~ .mobile-dropdown-menu {
    /* margin-top: 10px; */
    display: block;
    max-height: 16rem;
    opacity: 1;
    visibility: visible;
    transition: all .4s ease-in-out 0s!important;
}

/* individual items in the mobile dropdown menu */
.mobile-dropdown-menu li {
    /* margin-top: 10px; */
    padding-bottom: 0.62rem; /* 5px */
    border-bottom: none;
}

/* links within the dropdown menu */
.mobile-dropdown-menu li a {
    font-weight: 400;
    font-size: clamp(1.8vh, 0.75rem, 2vh) !important; /* 12px */
    line-height: 1rem; /* 16px */
    text-transform: none;
}

/* hover effect for dropdown menu links */
.mobile-dropdown-menu li a:hover {
    color: white;
}

/* arrow icon before the links in the dropdown */
.mobile-dropdown-menu li a::before {
    position: relative;
    content: "";
    display: inline-block;
    margin-right: 0.25rem;
    width: 0.75rem; /* 12px */
    height: 0.75rem; /* 12px */
    vertical-align: middle;
    transition: all 0.3s ease;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'/%3E%3C/svg%3E") no-repeat;
    background-size: 0.75rem 0.75rem; /* 12px 12px */
}

/* this initially shows the down arrow, and then rotates it clockwise */
.dropdown-checkbox:checked + .dropdown-toggle svg {
    transform: rotate(-180deg);
    transition: all 0.3s ease;
}

}
