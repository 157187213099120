/* the curved container that holds the chips */
.chip-container {
    position: relative;
    z-index: 1;
    margin-top: -2rem;
    /* padding-top: 2.5rem; */
    padding: 3.8rem clamp(20px, 3vw, 132px);
    /* padding-left: 2.5rem; */
    /* padding-right: 2.5rem; */
    background-color: white;
    border-radius: 2.5rem;
    overflow: hidden;
}

/* styling for the chips section - now using grid */
.chip-container {
    display: grid;

    /*
    this is the combo that dynamically adjusts the layout of the chip items based on the viewport width.
    here's how it works:
      1) autofill:
          - auto-fill ensures the available space is filled with as many grid columns as possible (based on the min/max sizes defined below)

      2) minmax: sets a minimum and maximum size for each column.
          - clamp(30%, (980px - 100vw) * 1000, 100%):
             - clamp() adjusts the column size dynamically based on viewport width
             - 3 values were used in this case:
               1. 30%: minimum width of each column
               2. (980px - 100vw) * 1000 (dynamic): this formula calculates width based on screen size.
                   - when the viewport width (100vw) is smaller than 980px, this value increases, causing columns to shrink and stack
                   - if the screen is wider than 980px, columns grow but don't exceed the max limit (showing 3 columns)
               3. 100%: max width of each column
         the result: columns shrink below 980px and fill the space when wider

      3) 1fr: sets the column to take up one fraction of the available space (when the viewport supports it).
         - ensures grid columns fill the available space up to the calculated width, using the clamp() function.
*/
    grid-template-columns: repeat(auto-fill,minmax(clamp(30%, (1030px - 100vw)*1000, 100%),1fr));
    gap: 2.5rem;
}

/* individual chip */
.chip {
    color: #fff;
    border-radius: 20px;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    /* margin-left: 0; */
    /* margin-right: 0; */
    justify-content: space-between;
}

.chip h2 {
    word-wrap: normal;
    font-size: clamp(25px, 5vw, 25px);
    color: white;
    margin-left: 2rem;
    margin-top: 0.6rem;
    margin-bottom: 1rem;
    z-index: 2;
    font-weight: 400;
    line-height: 1.1;
}


.chip a {
    margin-left: 2rem;
    margin-bottom: 1.5rem;
    align-self: flex-start;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #00359a;
    background: white;
    text-decoration: none;
    /* Please try not to use !important!!! just if it's really needed */
    padding: .5em 2em .5em .8em!important;
    border: 1px solid #ddd;
    border-radius: 1.25rem;
    transition: background-color 0.3s, color 0.3s;
    z-index: 2;
    position: relative;
}

.chip a:visited {
    color: #00359a;
}

.chip a:hover {
    background-color: #ddd;
    color: #00359a;
}

.chip img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: auto;
    max-width: 100%;
    max-height: 8rem;
    object-fit: contain;
    pointer-events: none;
    border-radius: 20px;
}

.chip a {
    position: relative;
}

.chip a svg {
    line-height: 1;
    color: inherit;
    position: absolute;
    top: 50%;
    right: 0.62rem;
    transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
    .chip h2 {
        margin-top: 1.2rem;
    }
}
