
/* DROPDOWN MENU */
#main-header .dropdown-menu {
    /* M: So many !important things nowaday... everything is !important... aaargh  */
    /* E: Removed here! */
    border-radius: 1.25rem;
    display: block !important; /* this avoids flex overrides */
    position: absolute;
    top: 83%;
    left: -23px;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    padding: 1.06rem 1.25rem 0 !important; /* important due to padding overrides */
    width: 12.5rem;
    border: 1px solid #ddd;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
    transform: translateY(10px); /* offset for animation */
    transition: all .3s ease-in-out 0s!important;
    /* M: across what...? :-) */
    font-size: 0.88rem; /* important for consistent text size */
    line-height: 1.25rem;
    font-weight: normal;
    margin: 0;
}

/* arrow above the dropdown */
.dropdown-menu::before {
    content: "";
    position: absolute;
    top: -0.88rem;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff; /* matches dropdown background */
    filter: drop-shadow(0 -1.9px 1px #00000026); /* shadow to the arrow */
    z-index: 1;
}

/* show dropdown on hover */
#main-header nav ul li:hover .dropdown-menu {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px); /* animates to the correct position */
}

/* dropdown items */
.dropdown-menu li {
    margin: 0 !important; /* important for overriding other potential styles */
    list-style: none;
}

/* arrow before the link */
.dropdown-menu li a::before {
    position: relative;
    top: 0.19rem;
    content: "";
    display: inline-block;
    margin-right: 0.25rem;
    width: 0;
    height: 0;
    vertical-align: middle;
    transition: all 0.3s ease; /* smooth transition */

    /* in this case, 'fill' part of the url specifies the color of the arrow
    the %23 is the encoded version of the # symbol, so %2300359a translates to #00359a,
    which is the hex value for the blue color */
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%2300359a' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'/%3E%3C/svg%3E") no-repeat;
    background-size: 14px 14px;
    transform: scaleX(0); /* hides the arrow */
}

/* on hover, reveal the arrow and shift the text */
.dropdown-menu li a:hover::before {
    width: 0.88rem;
    height: 0.88rem;
    transform: scaleX(1); /* shows the arrow */
}

/* dropdown link styling */
.dropdown-menu li a {
    text-transform: inherit !important; /* important to avoid default uppercase */
    line-height: 1.25rem;
    font-weight: 400 !important; /* ensure normal weight */
    margin-bottom: 0.56rem;
    padding-bottom: 0.56rem;
    display: flex;
    color: #000;
    text-decoration: none;
    font-size: 0.88rem !important;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s ease;
}

/* ensure dropdown hover effect is blue */
.dropdown-menu li a:hover {
    color: #00359a !important; /* ensure hover color stays blue */
}

/* remove border for the last item */
.dropdown-menu li:last-child a {
    border-bottom: none;
}
