body {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#main-header {
    grid-row: 1 / 2;
    width: 100%;
}

main {
    grid-row: 2 / 3;
    width: 100%;
    padding-top: 20px;
}

