/* navigation styles (applies only to desktop navigation) */
#main-header #desktop-nav ul {
    padding-left: 20px;
    padding-right: 90px;
    column-gap: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    /* padding: 0; */
    margin: 0 0;
    line-height: 96px;
}

#main-header #desktop-nav ul li {
    /* margin: 0 1.5rem; */
    position: relative; /* needed for the dropdown menu */
}

#main-header #desktop-nav ul li a {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    color: #000;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

#main-header #desktop-nav ul li a:hover {
    color: #28c83c;
}

/* eventi - different button */
#main-header #desktop-nav > ul > li:last-child > a {
    background-color: black;
    color: white;
    font-size: 0.875rem;
    padding: 0.6em 0.8em;
    border-radius: 8px;
    text-transform: uppercase;
    font-weight: normal;
    border: 1px solid transparent;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

#main-header #desktop-nav > ul > li:last-child > a:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
}