html {
    /*
     * It's important that you don't use the vw by itself in a font-size declaration;
     * we use a calc function to specify a minimum size and a scaling factor
     * See: https://web.dev/learn/design/typography#scaling_text
     */
    font-size: calc(0.55rem + 0.5vw);
}

/* public-sans-100 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 100;
    src: url('/fonts/public-sans-v15-latin-100.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* public-sans-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/public-sans-v15-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* public-sans-700 - latin */
@font-face {
    font-display: swap;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/public-sans-v15-latin-700.woff2') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Ranille';
    font-style: normal;
    src: url('/fonts/Ranille.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

body {
    font-family: 'Public Sans', sans-serif;
    line-height: 2;
    background-color: white;
}


h2 {
    font-weight: 700;
    line-height: 1;
}

p {
    font-size: 1rem;
    line-height: 1.6;
}

strong {
    font-weight: 700;
}


